<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
            background: none !important;
        }

        .page-break {
            page-break-before: always;
        }

        * {
            background: none !important;
        }

        header, footer, .print-button, #content-area * :not(.content-wrapper *) {
            display: none !important;
            visibility: hidden !important;
        }

        .router-view,
        .router-content {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th.bordered {
        border: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    #page-counter {
        display: table-footer-group;
        }
        
    #page-counter:after {
        counter-increment: page;
        content: ' ' counter(page) ' of ' counter(page);
    }
</style>
<style>
    /* Set the font size to 12px for printing */
    @media print {
        header, footer, .print-button, #content-area * :not(.content-wrapper *) {
            display: none !important;
            visibility: hidden !important;
        }

        .router-view,
        .router-content {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

</style>

<template>
    <div>
        <table class="" style="width:100%">
            <thead>
                <tr class="">
                    <th colspan="7">
                        <div class="title">
                            <p>PICKING LIST</p>
                            <p>PT. SINARMAS DISTRIBUSI NUSANTARA</p>
                        </div>
                    </th>
                </tr>
                <tr class="">
                    <th class=" py-0 w-32">Cabang</th>
                    <th class=" py-0 w-1">:</th>
                    <th class=" py-0 w-32">{{ data.warehouse.name }}</th>
                    <th class=" py-0 w-32"></th>
                    <th class=" py-0 w-5">Print</th>
                    <th class=" py-0 w-1" colspan="2">: {{  }}</th>
                </tr>
                <tr>
                    <th class=" py-0 w-32">Shipment Plan</th>
                    <th class=" py-0 w-1">:</th>
                    <th class=" py-0 w-32">{{  }}</th>
                    <th class=" py-0 w-32"></th>
                    <th class=" py-0 w-5">Print date</th>
                    <th class=" py-0 w-1" colspan="2">: {{ dateNow }}</th>
                </tr>
                <tr>
                    <th class=" py-0 w-32">Tanggal Shipment</th>
                    <th class=" py-0 w-1">:</th>
                    <th class=" py-0 w-32">{{  }}</th>
                    <th class=" py-0 w-32"></th>
                    <th class=" py-0 w-5">Print time</th>
                    <th class=" py-0 w-1" colspan="2">: {{ timeNow }}</th>
                </tr>
                <tr>
                    <th class=" py-0 w-32">Driver</th>
                    <th class=" py-0 w-1">:</th>
                    <th class=" py-0 w-32">{{  }}</th>
                    <th class=" py-0 w-32"></th>
                    <th class=" py-0 w-5">Page</th>
                    <th class=" py-0 w-1" colspan="2"><div id="page-counter">:</div></th>
                </tr>
                <tr>
                    <th class=" py-0 w-32">Vehicle Number</th>
                    <th class=" py-0 w-1">:</th>
                    <th class=" py-0 w-32">{{  }}</th>
                    <th class=" py-0 w-32"></th>
                    <th class=" py-0 w-5"></th>
                    <th class=" py-0 w-1"></th>
                    <th class=" py-0 w-5"></th>
                </tr>
            <!-- </thead>
            <thead> -->
                <tr>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">SKU Code</th>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;" colspan="2">SKU Name</th>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">BATCH</th>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">ED</th>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">QTY</th>
                    <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">UOM</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(tr, idxtr) in data.handover_lines" :key="idxtr">
                    <td class="py-1">{{ tr.item_unit.sku_code }}</td>
                    <td class="py-1" colspan="2">{{ tr.item_unit.item_name }}</td>
                    <td class="py-1">{{ tr.batch }}</td>
                    <td class="py-1">{{ formatDate(tr.exp_date) }}</td>
                    <td class="py-1">{{ tr.qty }}</td>
                    <td class="py-1">{{ tr.item_unit.name }}</td>
                </tr>
            </tbody>
        </table>
        <br/>
        <table class="remove_border">
            <tr>
                <th style="text-align:center;">Dibuat oleh</th>
                <th style="text-align:center;">Disiapkan oleh</th>
                <th style="text-align:center;">Diterima oleh</th>
            </tr>
            <tr>
                <th style="height: 50px;"></th>
                <th style="height: 50px;"></th>
                <th style="height: 50px;"></th>
            </tr>
            <tr>
                <th style="text-align:center;">Admin Logistic</th>
                <th style="text-align:center;">Picker</th>
                <th style="text-align:center;">Checker</th>
            </tr>
        </table>
        <div class="text-center">
            <vs-button class="print-button">Print</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    // props: {
    //     handover_id: Number
    // },
    data() {
        return {
            id: null,
            handoverIds: null,
            // table: [],
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            // logoSDN: logoSDN,
            showInfo: false,
            data: {
                handover_lines: [],
                warehouse: {},
            },
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.print()
        }
    },
    methods: {
        async print() {
            const tempTitle = document.title
            await this.getData()
            await window.print()
            document.title = tempTitle
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get("api/wms/v1/wms/outbound/supplier-return/handover/"+this.id);
            if (resp.code == 200) {
                console.log(resp.data)
                this.data = resp.data
                const now = new Date()
                this.dateNow = this.formatDate(now.setHours(now.getHours()+7))
                this.timeNow = this.formatTime(now)
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD/MM/YYYY');
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('hh:mm:ss');
            };
        },
    },
}
</script>
